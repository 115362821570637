import { cocheYaHttpClient } from '../../shared/cocheYaHttpClient';
import type { Subscription } from '../domain/Subscription';
import type { SubscriptionsService } from '../domain/SubscriptionService';

class RestSubscriptionService implements SubscriptionsService {
  async createSubscription(listingId: string): Promise<void> {
    try {
      await cocheYaHttpClient.put(`/subscriptions`, { listingId });
    } catch (error) {
      console.error('Error creating a subscription:', error);
      throw error;
    }
  }

  async getSubscriptions(): Promise<string[]> {
    try {
      const response = await cocheYaHttpClient.get<{ subscriptions: Subscription[] }>(
        `/subscriptions`
      );
      return response.subscriptions.map(s => s.listingId);
    } catch (e) {
      console.error('error fetching', e);
      return [];
    }
  }
}

export const restSubscriptionService = new RestSubscriptionService();
