import { cn } from '@/contexts/shared/ui/twMerge/twMerge';
import { FC } from 'react';

export type WithChildren<T = {}> = T & { children: React.ReactNode; className?: string };

export const H1: FC<WithChildren> = ({ children, className }) => (
  <h1 className={cn(`text-4xl md:text-5xl font-extrabold mt-8 mb-6`, className)}>{children}</h1>
);
export const H2: FC<WithChildren> = ({ children, className }) => (
  <h2 className={cn(`text-2xl font-bold mt-8 mb-4`, className)}>{children}</h2>
);
export const H3: FC<WithChildren> = ({ children, className }) => (
  <h3 className={cn(['text-lg font-semibold tracking-tight w-full', className])}>{children}</h3>
);
