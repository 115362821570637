'use client';

import React, { Suspense } from 'react';

import { Button } from '@/contexts/shared/ui/designSystem/button';
import { Card, CardContent } from '@/contexts/shared/ui/designSystem/card';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from '@/contexts/shared/ui/designSystem/drawer';
import { BellIcon, CrossedBellIcon, FuelIcon, GaugeIcon, LineChartIcon } from '@/contexts/shared/ui/designSystem/icons';
import { useToast } from '@/contexts/shared/ui/designSystem/toast/use-toast';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/contexts/shared/ui/designSystem/tooltip';
import { signIn, useSession } from 'next-auth/react';
import Image from 'next/image';
import { calculateRelativeTime } from '../../../../../../../../contexts/coche-ya/shared/Formatter';
import type { Listing } from '../../../../../modules/listings/domain/listings';
import { useCreateSubscription } from './hooks/useCreateSubscription';
import { useSubscriptions } from './hooks/useSubscriptions';

const ListingHistoryDrawer = React.lazy(() => import('../../ListingHistory/ListingHistoryDrawer'));

// TODO: I want, the bell icon appear when the subscriptions are loaded
// TODO: I want to prefetch the history when hovering  
export const ListingCard = ({ listing }: { listing: Listing }) => {
  return (
    <Card className="group relative overflow-hidden rounded-lg shadow-md transition-all hover:shadow-lg dark:bg-gray-950">
      <CardContent className={'p-0'}>
        <a href={listing.url} target='_blank'>
          {!listing.img.includes('undefined') ? (
            <Image
              src={listing.img}
              alt={listing.name}
              className="h-64 w-full object-cover transition-all group-hover:scale-105 bg-gradient-to-t from-transparent to-black"
              height={200}
              width={300}
            />
          ) : (
            <img
              src={listing.img}
              alt={listing.name}
              className="h-64 w-full object-cover transition-all group-hover:scale-105 bg-gradient-to-t from-transparent via-transparent via-90% to-black/50"
              height={200}
              width={300}
            />
          )}
        </a>
        <div className="p-4">
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <h3 className="text-lg font-semibold tracking-tight w-full text-blue-500 truncate">
                {listing.version}
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">{listing.year}</p>
            </div>
          </div>
          <div className="mt-4 flex items-center justify-between">
            <div className="flex items-center gap-1">
              <FuelIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
              <span className="text-sm text-gray-500 dark:text-gray-400">{listing.fuel}</span>
            </div>
            <div className="text-lg font-semibold text-green-500">{listing.humanReadablePrice}</div>
          </div>
          <div className="mt-4 flex items-center justify-between">
            <div className="flex items-center gap-1">
              <GaugeIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {listing.humanReadableKms}
              </span>
            </div>
            <span className="text-sm text-gray-500 dark:text-gray-400">
              Publicado {calculateRelativeTime(listing.publishedAt)}
            </span>
          </div>
          <ListingCardActions id={listing.id} />
        </div>
      </CardContent>
    </Card>
  );
};

const ListingCardActions = ({ id }: { id: string }) => {
  const { createSubscription } = useCreateSubscription();
  const { subscriptions } = useSubscriptions()
  const { status } = useSession();
  const { toast } = useToast();

  if (!subscriptions) return null

  const isSubscribed = subscriptions.includes(id)

  const onSubscription = () => {
    if (status !== 'authenticated') {
      signIn();
    } else {
      createSubscription(id, {
        onSuccess: () =>
          toast({
            title: 'Suscripcion creada!',
            description: 'Te mandaramos un email si el coche baja de precio'
          }),
        onError: () =>
          toast({
            description: 'Ha ocurrido un error creando la suscripcion :( ',
            variant: 'destructive'
          })
      });
    }
  };

  return (
    <TooltipProvider>
      <div className="absolute top-2 right-2">
        <Tooltip>
          {/* todo: why bell has cycle and chart n */}
          <TooltipTrigger asChild>
            <Button
              onClick={onSubscription}
              className="bg-white/80 hover:bg-white dark:bg-gray-950/80 dark:hover:bg-gray-950 p-2 rounded-full shadow-md text-red-500"
              size="icon"
              variant="outline"
            >
              {isSubscribed ? <CrossedBellIcon className="h-5 w-5" /> : <BellIcon className="h-5 w-5" />}
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Recibe un email cuando el precio baje</p>
          </TooltipContent>
        </Tooltip>
      </div>
      <div className="absolute top-2 right-12">
        <Tooltip>
          <Drawer>
            <TooltipTrigger asChild>
              <DrawerTrigger asChild>
                <Button
                  className="bg-white/80 hover:bg-white dark:bg-gray-950/80 dark:hover:bg-gray-950 p-2 rounded-full shadow-md text-sm text-gray-500 dark:text-gray-400"
                  size="icon"
                  variant="outline"
                >
                  <LineChartIcon className="h-5 w-5" />
                </Button>
              </DrawerTrigger>
            </TooltipTrigger>
            <TooltipContent>
              <p>Ver evolucion del precio</p>
            </TooltipContent>
            <Suspense fallback={null}>
              <DrawerContent>
                <DrawerHeader>
                  <DrawerTitle>Evolucion del Coche</DrawerTitle>
                  <DrawerDescription>
                    <ListingHistoryDrawer id={id} />
                  </DrawerDescription>
                </DrawerHeader>
                <DrawerFooter>
                  <Button>Submit</Button>
                  <DrawerClose>
                    <Button variant="outline">Cancel</Button>
                  </DrawerClose>
                </DrawerFooter>
              </DrawerContent>
            </Suspense>
          </Drawer>
        </Tooltip>
      </div>
    </TooltipProvider>
  );
}
