'use client';

import { useState } from 'react';

import { Button } from '@/contexts/shared/ui/designSystem/button';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/contexts/shared/ui/designSystem/collapsible';
import { H2 } from '@/contexts/shared/ui/designSystem/headings';
import { cn } from '../../../../../../shared/ui/twMerge/twMerge';
import type { Listings } from '../../../../../modules/listings/domain/listings';
import { ListingCard } from './Listing';


type Props = { listings: Listings, className?: string };

export function Listings({ listings, className }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const firstRow = listings.slice(0, 4);
  const rest = listings.slice(4, 100);

  return (
    <>
      <div className={cn("space-y-4 mx-4 sm:mx-8 md:mx-12 md:pt-16 lg:mx-24 xl:mx-48", className)}>
        <H2>Coches en venta</H2>

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {firstRow.map(listing => <ListingCard key={listing.id} listing={listing} />)}
        </div>
      </div >
      <Collapsible
        open={isOpen}
        onOpenChange={setIsOpen}
        className="space-y-4 md:pb-16 mt-12 mx-4 sm:mx-8 md:mx-12 lg:mx-24 xl:mx-48 "
      >
        <div className="flex items-center justify-center space-x-4 px-4 w-full">
          <CollapsibleTrigger asChild={true}>
            <Button variant="default" size="sm">
              {isOpen ? 'Esconder' : 'Ver'} todos los anuncios
            </Button>
          </CollapsibleTrigger>
        </div>
        <CollapsibleContent className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {rest.map(listing => (
            <ListingCard key={listing.id} listing={listing} />
          ))}
        </CollapsibleContent>
      </Collapsible>
    </>
  );
}
