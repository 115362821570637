import { useMutation } from '@tanstack/react-query';
import { restSubscriptionService } from '../../../../../../modules/subscriptions/infrastructure/RestSubscriptionService';

export const useCreateSubscription = () => {
  const mutation = useMutation({
    mutationKey: ['createSubscription'],
    mutationFn: restSubscriptionService.createSubscription
  });

  return { createSubscription: mutation.mutate };
};
