const fromValueToCurrency = (value: number) =>
  new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    notation: 'compact'
  }).format(value);

const fromValueToKms = (value: number) =>
  new Intl.NumberFormat('es-ES', {
    style: 'unit',
    unit: 'kilometer',
    maximumFractionDigits: 0,
    notation: 'compact'
  }).format(value);

const fromDateToDayAndMonth = (date: Date) =>
  new Intl.DateTimeFormat('es-ES', { day: 'numeric', month: 'short' }).format(date);

export const Formatter = {
  currency: fromValueToCurrency,
  kms: fromValueToKms,
  dayAndMonth: fromDateToDayAndMonth
};

export const calculateRelativeTime = (targetDate: Date) => {
  const currentDate = new Date();
  const targetDateTime = new Date(targetDate);

  const timeDifference = currentDate.getTime() - targetDateTime.getTime();
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const rtf = new Intl.RelativeTimeFormat('es', { numeric: 'auto', style: 'short' });

  if (days > 0) {
    return rtf.format(-days, 'day');
  } else if (hours > 0) {
    return rtf.format(-hours, 'hour');
  } else if (minutes > 0) {
    return rtf.format(-minutes, 'minute');
  } else {
    return rtf.format(-seconds, 'second');
  }
};
