import { useQuery } from '@tanstack/react-query';
import { restSubscriptionService } from '../../../../../../modules/subscriptions/infrastructure/RestSubscriptionService';

export const useSubscriptions = () => {
  const { data } = useQuery({
    queryKey: ['subscription'],
    queryFn: restSubscriptionService.getSubscriptions
  });

  return { subscriptions: data };
};
