interface RequestOptions {
  method: string;
  headers: Record<string, string>;
  body?: string;
}

interface ApiClientConfig {
  baseUrl: string;
  defaultHeaders?: Record<string, string>;
}

export class HTTPClient {
  private baseUrl: string;
  private defaultHeaders: Record<string, string>;

  constructor(config: ApiClientConfig) {
    this.baseUrl = config.baseUrl;
    this.defaultHeaders = config.defaultHeaders || {};
  }

  private async request<T>(
    endpoint: string,
    method: string = 'GET',
    data: any = null,
    headers: Record<string, string> = {}
  ): Promise<T> {
    const url = `${this.baseUrl}${endpoint}`;
    const options: RequestOptions = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...this.defaultHeaders,
        ...headers
      }
    };

    if (data) {
      options.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('API request failed:', error);
      throw error;
    }
  }

  public get<T>(endpoint: string, headers: Record<string, string> = {}): Promise<T> {
    return this.request<T>(endpoint, 'GET', null, headers);
  }

  public post<T>(endpoint: string, data: any, headers: Record<string, string> = {}): Promise<T> {
    return this.request<T>(endpoint, 'POST', data, headers);
  }

  public put<T>(endpoint: string, data: any, headers: Record<string, string> = {}): Promise<T> {
    return this.request<T>(endpoint, 'PUT', data, headers);
  }

  public patch<T>(endpoint: string, data?: any, headers: Record<string, string> = {}): Promise<T> {
    return this.request<T>(endpoint, 'PATCH', data, headers);
  }

  public delete<T>(endpoint: string, data?: any, headers: Record<string, string> = {}): Promise<T> {
    return this.request<T>(endpoint, 'DELETE', data, headers);
  }
}
